import React from 'react';
import isNil from 'lodash/isNil';
import Select from 'react-select-next';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { defaultOptionRenderer } from './selectorOptionRenderers/defaultOptionRenderer';

export const SelectorField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    options,
    disabled,
    isClearable = false,
    onChange,
    onBlur,
    placeholder,
    optionRenderer = defaultOptionRenderer(),
    headerRenderer = optionRenderer,
    resourceName,
    id,
  } = props;

  const inputId = id || `${resourceName}-${name}`;
  const selectorValue = options.find(o => o.value === value);

  const defaultOnChange = async selection => {
    await setFieldValue(name, isNil(selection) && isClearable ? null : selection.value);
    await setFieldTouched(name, true);
  };
  const defaultOnBlur = async () => {
    await setFieldTouched(name, true);
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <div style={{ width: '100%' }}>
        <Select
          inputId={inputId}
          classNamePrefix={inputId}
          value={selectorValue}
          options={options}
          styles={selectorCustomStyles}
          isDisabled={disabled}
          isClearable={isClearable}
          placeholder={placeholder}
          menuPlacement="auto"
          onChange={selection => {
            onChange ? onChange(selection, defaultOnChange) : defaultOnChange(selection);
          }}
          onBlur={e => {
            onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
          }}
          formatOptionLabel={(option, { context }) =>
            context === 'value' ? headerRenderer(option.data) : optionRenderer(option.data)
          }
        />
      </div>
    </FieldWrapper>
  );
};

import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import cloneDeep from 'lodash/fp/cloneDeep';
import permitParams from 'permit-params';
import { validateForm } from '../../services/formHelper';
import validationSchema from './ValidationSchema';
import reopenValidationSchema from './ReopenValidationSchema';
import Form from './Form';
import { API } from '../../services/api';
import { getHiddenDefinitionsIds } from './helpers';

const translate = (internal, key) => {
  const translation_scope = internal ? 'internal' : 'external';
  return I18n.t(`salute_portal.api.inspections_controller.${translation_scope}.${key}`);
};

const equipmentObjects = ['Equipment', 'PfE'];
const locationObjects = ['Campus', 'Building', 'BuildingsFloor', 'Lab'];

const setInitialFlashMessageForNextPage = message =>
  sessionStorage.setItem('flashMessage', message);

export const InspectionFunctions = {
  apiPaths: {
    internal: {
      show: API.inspection.internal.show,
      new: API.inspection.internal.new,
      create: {
        draft: API.inspection.internal.create,
        finalize: API.inspection.internal.createAndFinalize,
      },
      update: {
        draft: API.inspection.internal.update,
        finalize: API.inspection.internal.finalize,
        reopen: API.inspection.internal.reopen,
      },
      delete: API.inspection.internal.delete,
    },
    external: {
      show: API.inspection.external.show,
      new: API.inspection.external.new,
      create: {
        draft: API.inspection.external.create,
        finalize: API.inspection.external.createAndFinalize,
      },
      update: {
        draft: API.inspection.external.update,
        finalize: API.inspection.external.finalize,
        reopen: API.inspection.external.reopen,
      },
      delete: API.inspection.external.delete,
    },
  },
  permitParams: inspection => {
    return permitParams(
      inspection,
      'inspection_type_id',
      'date_inspected',
      'status',
      'ehs_inspector_id',
      'responsible_person_id',
      'location_comment',
      'investigable_type',
      'investigable_id',
      'audit_comment',
      'send_email_notifications',
      'send_email_notifications_for_findings',
      'agency_id',
      'scheduled',
      'agency_inspector_name',
      'greeting_person_id',
      'supervising_person_id',
      'assessment_queue_id',
      'people_group_id',
      { inspections_also_notified_people_attributes: ['id', 'person_id', '_destroy'] },
      {
        inspection_checklist_questions_attributes: [
          'id',
          'answer',
          'inspection_finding_definition_id',
        ],
      },
    );
  },
  inspectionApi: formikProps => {
    return InspectionFunctions.apiPaths[
      formikProps.props.internal_inspection ? 'internal' : 'external'
    ];
  },
  create: async (inspection, formikProps, submitType, aqRedirectId) => {
    formikProps.setSubmitting(true);
    return InspectionFunctions.inspectionApi(formikProps)
      .create[submitType]({
        inspection: InspectionFunctions.permitParams(inspection),
        ...formikProps.props.params,
      })
      .then(async response => {
        const { data, ok } = response;
        if (ok) {
          const newInspection = data.data.attributes;
          const flashMessage = translate(
            formikProps.props.internal,
            `flash_messages.success.${snakeCase(submitType)}`,
          );
          if (inspection.createSimilar) {
            setInitialFlashMessageForNextPage(flashMessage);
            window.location.href = `/inspections/new?internal=${formikProps.props.internal}&ref_inspection_id=${newInspection.id}`;
          } else if (submitType === 'finalize' && aqRedirectId) {
            setInitialFlashMessageForNextPage(flashMessage);
            window.location.href = `/assessment_queues/${inspection.aqRedirectId}/edit`;
          } else {
            await InspectionFunctions.updateValues(newInspection, formikProps);
            formikProps.props.setNewInitialInspection(newInspection);
            window.history.pushState(
              '',
              '',
              `/inspections/${newInspection.id}/edit${window.location.search}`,
            );
            window.flashMessage(flashMessage);
            InspectionFunctions.updateBreadcrumbs(formikProps.props.internal);
          }
        } else {
          InspectionFunctions.displayErrorMessage(data);
        }
        formikProps.setSubmitting(false);
      });
  },
  updateCommentAndFile: (values, formikProps) => {
    formikProps.setSubmitting(true);
    const { id } = values;
    const { activeQuestion } = values;
    const updateCommentAndFile = () => {
      const { inspection_checklist_questions_attributes: items } = values;
      const item = cloneDeep(items.find(_item => _item.id === activeQuestion.id));
      return API.inspection.inspectionChecklistQuestion
        .saveCommentAndFile(id, item.id, item)
        .then(response => {
          if (response.ok) {
            window.flashMessage('Checklist was successfully updated');
          } else {
            window.flashMessage('Unable to save observations agent', true, 'error');
          }
          values.afterSave(response);
          formikProps.setFieldValue('submit', 'draft');
          formikProps.setFieldValue('afterSave', () => {});
          formikProps.setSubmitting(false);
        });
    };
    if (!id) {
      return InspectionFunctions.create(values, formikProps, 'draft').then(() => {
        updateCommentAndFile();
      });
    }
    return updateCommentAndFile();
  },
  update: (inspection, formikProps, submitType, aqRedirectId) => {
    formikProps.setSubmitting(true);

    return InspectionFunctions.inspectionApi(formikProps)
      .update[submitType](inspection.id, {
        inspection: InspectionFunctions.permitParams(inspection),
      })
      .then(async response => {
        const { data, ok } = response;
        if (ok) {
          const newInspection = data.data.attributes;
          await InspectionFunctions.updateValues(newInspection, formikProps);
          formikProps.props.setNewInitialInspection(newInspection);

          const flashMessage = translate(
            formikProps.props.internal,
            `flash_messages.success.${snakeCase(submitType)}`,
          );
          if (inspection.createSimilar) {
            setInitialFlashMessageForNextPage(flashMessage);
            window.location.href = `/inspections/new?internal=${formikProps.props.internal}&ref_inspection_id=${inspection.id}`;
          } else if (submitType === 'finalize' && aqRedirectId) {
            setInitialFlashMessageForNextPage(flashMessage);
            window.location.href = `/assessment_queues/${aqRedirectId}/edit`;
          } else {
            window.flashMessage(flashMessage);
          }
        } else {
          InspectionFunctions.displayErrorMessage(data);
        }
        formikProps.setSubmitting(false);
      });
  },
  delete: (inspection, formikProps) => {
    return InspectionFunctions.inspectionApi(formikProps)
      .delete(inspection.id, {
        inspection_finding: inspection,
      })
      .then(response => {
        const { ok, data } = response;
        if (ok) {
          const successMsg = translate(formikProps.props.internal, `flash_messages.success.delete`);
          window.location.href = `/inspections?internal=${formikProps.props.internal}&notice=${successMsg}`;
        } else {
          InspectionFunctions.displayErrorMessage(data, true);
        }
        formikProps.setSubmitting(false);
      });
  },
  displayErrorMessage: (data, deleteError = false) => {
    const { message } = data;
    if (message && message.length > 0) {
      window.flashMessage(message[0], true, 'error');
    } else {
      const flashMsg = translate(`flash_messages.${deleteError ? 'delete_error' : 'error'}`);
      window.flashMessage(flashMsg, true, 'error');
    }
  },
  updateBreadcrumbs: isInternal => {
    const selector = $('.navbar_links a:last-child');
    selector.attr('href', window.location.href);
    selector.text(
      I18n.t(
        `activerecord.attributes.inspection.${
          isInternal ? 'internal' : 'external'
        }_inspection.inspection`,
      ),
    );
  },
  updateValues: async (data, formik) => {
    Object.keys(data).forEach(async k => {
      await formik.setFieldValue(k, data[k]);
    });
  },
  setObjectType: investigable_type => {
    const type = investigable_type || '';

    if (equipmentObjects.includes(type)) {
      return 'Equipment';
    }
    if (locationObjects.includes(type)) {
      return 'Location';
    }

    return type;
  },
  defaultValues: ({ initialInspection, params, internal }) => {
    const processingOrder = initialInspection.checklist_questions_processing_order || [];
    const items = initialInspection.inspection_checklist_questions_attributes;
    return {
      id: '',
      identifier: '',
      inspection_type_id: '',
      date_inspected: '',
      status: '',
      ehs_inspector_id: '',
      responsible_person_id: '',
      inspections_also_notified_people_attributes: [],
      location_comment: '',
      investigable_type: '',
      investigable_id: '',
      object_type: InspectionFunctions.setObjectType(initialInspection.investigable_type),
      people_group_id: '',
      audit_comment: '',
      agency_id: '',
      scheduled: false,
      agency_inspector_name: '',
      greeting_person_id: '',
      supervising_person_id: '',
      assessment_queue_id: '',
      inspection_checklist_questions_attributes: items,
      afterSave: () => {},
      internal,
      submit: 'draft',
      openModal: '',
      resetting_timeline: false,
      send_email_notifications: false,
      send_email_notifications_for_findings: false,
      createSimilar: false,
      aqRedirectId: params.ref_assessment_queue_id || initialInspection.assessment_queue_id,
      checklist_questions_processing_order: processingOrder,
      hiddenDefinitionsIds: getHiddenDefinitionsIds(items || [], processingOrder),
      activeQuestion: {},
      activeViolation: {},
    };
  },
};

export const InspectionForm = withFormik({
  validate: values => {
    if (values.submit === 'delete') {
      return {};
    }
    if (values.submit === 'reopen') {
      return validateForm(values, reopenValidationSchema);
    }
    return validateForm(values, validationSchema);
  },
  mapPropsToValues: props => {
    return { ...InspectionFunctions.defaultValues(props), ...props.initialInspection };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (values.submit === 'delete') {
      InspectionFunctions.delete(values, formikProps);
    } else if (values.submit === 'update_checklist_comment') {
      InspectionFunctions.updateCommentAndFile(values, formikProps);
    } else if (!values.id) {
      InspectionFunctions.create(values, formikProps, values.submit, values.aqRedirectId)
        .then(response => {
          values.afterSave(response);
          formikProps.setFieldValue('afterSave', () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      InspectionFunctions.update(values, formikProps, values.submit, values.aqRedirectId)
        .then(response => {
          values.afterSave(response);
          formikProps.setFieldValue('afterSave', () => {});
        })
        .then(() => {
          formikProps.setFieldValue('audit_comment', '');
          formikProps.setTouched({});
        });
    }
  },
})(Form);

export const colors = {
  error: '#DC3545',
  delete: '#DC3545',
  inputBorderColor: 'rgba(0, 0, 0, 0.15)',
  sectionSeparatorColor: 'rgba(0, 0, 0, 0.1)',
  white: '#FFF',
  lightGray: '#CAD0E3',
  inputTextColor: '#495057',
  grayBackgroundColor: '#F6F7FB',
  grayDark: '#354052',
  required: '#E32',
  primaryBlue: '#75C0CA',
  disabled: '#EDEFF7',
  outline: 'rgba(117, 192, 202, 1)',
  tableHeader: '#57799C',
  brand: '#DF3600',
  brandDarken: '#C63000',
  brandLighten: '#DF3600B8',
  grayBg: '#F6F6F8',
  grayCross: '#BDBDBD',
  badge: '#DADADA',
  success: '#00B16A',
  download: '#17A2B3',
  link: '#007bff',
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { compact } from 'lodash';
import { SaluteModal } from '../../../stateless_components/modals/SaluteModal';
import { PrimaryButton } from '../../../stateless_components/buttons/PrimaryButton';
import { ModalFooter } from '../../../stateless_components/modals/ModalFooter';
import { SaluteInputField } from '../../../stateless_components/inputs/fields/SaluteInputField';
import { getFormikErrors } from '../../../services/formHelper';
import {
  INPUT_TYPES,
  FIELD_WIDTHS,
} from '../../../stateless_components/inputs/elements/FieldWrapper';
import { ButtonLink } from '../../../stateless_components/buttons/ButtonLink';

class CommentFileBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.props.shouldOpenModal) {
      this.openModal(false);
    }
  };

  noExplanation = () => {
    const { attachments, comment, links } = this.props;
    const attachmentsCollection = compact(attachments);
    const linksCollection = compact(links);
    return !comment && attachmentsCollection.length + linksCollection.length === 0;
  };

  btnClass = () => {
    if (this.noExplanation()) {
      return 'no_comment_and_file';
    }
    return '';
  };

  btnStroke = () => {
    if (this.props.explanationRequired && this.noExplanation()) {
      return 'button_stroke';
    }
    return 'border-0';
  };

  btnBody = () => {
    const { attachments, comment, links } = this.props;
    const linksAttachmentsCollection = attachments.concat(links);
    const maybeActiveClassFor = field => (field && field.length > 0 ? 'active' : '');

    if (!this.noExplanation()) {
      return (
        <>
          <div className="col-6 d-flex align-content-center">
            <span className={`${maybeActiveClassFor(comment)} saluteicons saluteicons-comment`} />
          </div>

          <div className="col-6 d-flex align-content-center">
            <span
              className={`${maybeActiveClassFor(
                linksAttachmentsCollection,
              )} saluteicons saluteicons-paperclip`}
            />
          </div>
        </>
      );
    }
    return this.props.commentBtnLabel;
  };

  openModal = async (canBePostponed = true) => {
    const { canOpenModal, findingDefinitionId } = this.props;
    if (canOpenModal) {
      if (await canOpenModal(canBePostponed ? findingDefinitionId : null, 'CommentFileBtn')) {
        this.setState({ modalOpen: true });
      }
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  modal = () => {
    const { comment, attachments, links, questionID, disabled } = this.props;

    const onSave = response => {
      if (response.ok) this.closeModal();
    };

    return (
      <SaluteModal
        isOpen={this.state.modalOpen}
        onCloseClick={this.closeModal}
        title="Explanation and Attachments"
      >
        <Formik
          initialValues={{
            comment: comment || '',
            attachments: attachments || [],
            links: links || [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            const saveResponse = this.props.saveCommentAndFile(
              questionID,
              values.comment,
              values.attachments,
              values.links,
              onSave,
            );
            if (saveResponse) {
              saveResponse.then(response => {
                onSave(response);
              });
            }
            setSubmitting(false);
          }}
        >
          {formikProps => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formikProps;

            return (
              <>
                <SaluteInputField
                  labelProps={{ label: 'Explanation' }}
                  inputProps={{
                    handleBlur,
                    value: values.comment,
                    name: 'comment',
                    disabled,
                    onChange: handleChange,
                  }}
                  fieldWidth={FIELD_WIDTHS.WIDE}
                  error={getFormikErrors('comment', touched, errors)}
                  type={INPUT_TYPES.TEXT_AREA}
                />
                <SaluteInputField
                  labelProps={{ label: 'Attachments' }}
                  inputProps={{
                    handleBlur,
                    disabled,
                    value: values.attachments,
                    name: 'attachments',
                    onChange: handleChange,
                    s3Secure: true,
                  }}
                  fieldWidth={FIELD_WIDTHS.WIDE}
                  error={getFormikErrors('attachments', touched, errors)}
                  type={INPUT_TYPES.MULTIPLE_FILES_UPLOAD}
                />
                <SaluteInputField
                  labelProps={{ label: 'Links' }}
                  inputProps={{
                    handleBlur,
                    disabled,
                    value: values.links,
                    name: 'links',
                    modalOnModal: true,
                  }}
                  fieldWidth={FIELD_WIDTHS.WIDE}
                  error={getFormikErrors('links', touched, errors)}
                  type={INPUT_TYPES.LINKS}
                />
                <ModalFooter>
                  <ButtonLink secondary onClick={this.closeModal} fontSizeVariant="textMd">
                    Cancel
                  </ButtonLink>
                  <div className="ml-md-3 mb-sm-3"></div>
                  {!disabled && (
                    <PrimaryButton onPress={handleSubmit} disabled={isSubmitting}>
                      Save
                    </PrimaryButton>
                  )}
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </SaluteModal>
    );
  };

  render = () => {
    return (
      <>
        <button
          type="button"
          className={`comment_button no-outline ${this.btnStroke()} ${this.btnClass()}`}
          onClick={this.openModal}
        >
          <div className="row" data-toggle="tooltip" placement="top" title="Add Comment / File">
            {this.btnBody()}
          </div>
        </button>
        {this.modal()}
      </>
    );
  };
}

CommentFileBtn.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  comment: PropTypes.string,
  saveCommentAndFile: PropTypes.func.isRequired,
  questionID: PropTypes.number.isRequired,
  commentBtnLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CommentFileBtn.defaultProps = {
  attachments: [],
  comment: '',
  links: [],
};

export default CommentFileBtn;

/** Lib */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { ProgressBar } from '../../../other/ProgressBar';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { UploadIcon } from '../../../icons/UploadIcon';
import {
  StyledAttachmentLink,
  StyledAttachmentNameSpan,
  StyledAttachmentRow,
  StyledAttachmentsTable,
  StyledMockUploadButton,
  StyledMockUploadButtonTitle,
  StyledRemoveAttachmentButton,
} from './AuxiliaryStyledComponents';
import { getUuid } from '../../../../services/uuidHelper';
import { bytesToMb } from '../../../../services/fileSizeHelper';
import _ from 'lodash';
import { ErrorMessage } from '../ErrorMessage';
import ReactS3Uploader from 'react-s3-uploader';

const AttachmentRow = function(props) {
  const { name, attachment, removeAttachment } = props;
  var attachmentPath = new URL(attachment).pathname.split('/');
  let attachmentFilename = decodeURIComponent(_.last(attachmentPath));

  return (
    <StyledAttachmentRow>
      <Field name={name} hidden={true} />
      <StyledAttachmentNameSpan>{attachmentFilename}</StyledAttachmentNameSpan>
      <StyledAttachmentLink download={attachmentFilename} href={attachment}>
        <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
      </StyledAttachmentLink>
      <StyledRemoveAttachmentButton onClick={removeAttachment}>
        {I18n.t('file_uploader.delete_file')}
      </StyledRemoveAttachmentButton>
    </StyledAttachmentRow>
  );
};

export class MultipleFilesUploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputId = getUuid();
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
    };
  }

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t('file_uploader.errors.too_large_file_unit')}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = (signedUrl, arrayHelpers) => {
    let downloadPath = signedUrl['signedUrl'].split('?')[0];
    if (this.props.s3Secure) {
      downloadPath = { url: downloadPath };
    }
    arrayHelpers.push(downloadPath);
    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
    });
  };

  render() {
    const { name, value, disabled = false, s3Secure = false } = this.props;

    const attachments = s3Secure ? value.map(attachment => attachment.url) : value;

    return (
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <div>
            <StyledAttachmentsTable>
              {attachments.map((attachment, index) => (
                <AttachmentRow
                  attachment={attachment}
                  name={`${name}[${index}]`}
                  key={`${name}[${index}]`}
                  removeAttachment={() => arrayHelpers.remove(index)}
                />
              ))}
            </StyledAttachmentsTable>
            <ReactS3Uploader
              disabled={disabled}
              id={this.fileInputId}
              signingUrl="/file_upload"
              signingUrlMethod="GET"
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={(signedUrl, file) => this.onUploadSuccess(signedUrl, arrayHelpers)}
              autoUpload={true}
              contentDisposition="attachment"
              scrubFilename={filename => encodeURIComponent(filename.trim())}
              hidden={true}
            />
            <ProgressBar
              progressValue={this.state.progressValue}
              hidden={!this.state.showProgressBar}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={this.state.errorOccurred}
            />
            <ErrorMessage hidden={!this.state.errorOccurred}>
              {this.state.error}
            </ErrorMessage>
            <label htmlFor={this.fileInputId} hidden={!this.state.showUploadButton}>
              <StyledMockUploadButton disabled={disabled}>
                <UploadIcon />
                <StyledMockUploadButtonTitle>
                  {I18n.t('file_uploader.upload_file')}
                </StyledMockUploadButtonTitle>
              </StyledMockUploadButton>
            </label>
          </div>
        )}
      />
    );
  }
}

MultipleFilesUploadInput.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  s3Secure: PropTypes.bool,
};

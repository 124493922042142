import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select-next';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { colors } from '../../assets/styles/colors';
import { prepareTimeFieldOptions } from './auxiliary/prepareTimeFieldOptions';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { defaultOptionRenderer } from './selectorOptionRenderers/defaultOptionRenderer';
import { TimeFieldValueContainerRenderer } from './auxiliary/TimeFieldValueContainerRenderer';
import { isDefined } from '../../services/utils';
import {
  getConvertedMoment,
  getConvertedMomentInFixedTimeZone,
  momentFormatter,
  newMoment,
  newMomentInFixedTimeZone,
  dateFormatter,
} from '../../services/dateFormatUtils';

const MOBILE_TABLET_WIDTH = 1024;

export const DateTimeField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    placeholder,
    timeZones,
    resourceName,
    id,
  } = props;
  const [focused, setFocused] = useState(false);
  const [options, setOptions] = useState(prepareTimeFieldOptions());
  const defaultOnChange = async newValue => {
    await setFieldValue(name, newValue);
    await setFieldTouched(name, true);
  };
  const defaultOnBlur = async () => {
    await setFieldTouched(name, true);
  };

  const onDateChange = newDate => {
    let newValue = '';
    if (timeZones) {
      const { fixedTimeZone, inputTimeZone } = timeZones;
      const timeSuffixMoment =
        getConvertedMomentInFixedTimeZone(value, fixedTimeZone, inputTimeZone) ||
        newMomentInFixedTimeZone(inputTimeZone).set({
          hours: 12,
          minutes: 0,
          seconds: 0,
        });
      newValue = isDefined(newDate)
        ? momentFormatter.serverDateTime(
            getConvertedMomentInFixedTimeZone(newDate, fixedTimeZone, inputTimeZone).set({
              hours: timeSuffixMoment.hours(),
              minutes: timeSuffixMoment.minutes(),
              seconds: 0,
            }),
          )
        : null;
    } else {
      const timeSuffixMoment =
        getConvertedMoment(value) ||
        newMoment().set({
          hours: 12,
          minutes: 0,
          seconds: 0,
        });
      newValue = isDefined(newDate)
        ? momentFormatter.serverDateTime(
            getConvertedMoment(newDate).set({
              hours: timeSuffixMoment.hours(),
              minutes: timeSuffixMoment.minutes(),
              seconds: 0,
            }),
          )
        : null;
    }
    onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue);
  };

  const onTimeChange = selection => {
    let momentInstance = '';
    if (timeZones) {
      const { fixedTimeZone, inputTimeZone } = timeZones;
      momentInstance = value
        ? getConvertedMomentInFixedTimeZone(value, fixedTimeZone, inputTimeZone)
        : newMomentInFixedTimeZone(inputTimeZone);
    } else {
      momentInstance = value ? getConvertedMoment(value) : newMoment();
    }
    momentInstance.set({
      hours: selection.hours,
      minutes: selection.minutes,
      seconds: 0,
    });
    const newValue = momentFormatter.serverDateTime(momentInstance);
    onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue);
  };
  const dateFieldId = id ? `${id}-date` : `${resourceName}-${name}-date`;
  const timeFieldId = id ? `${id}-time` : `${resourceName}-${name}-time`;
  const width = window.innerWidth;
  return (
    <FieldWrapper {...fieldWrapperProps({ inputId: dateFieldId, ...props })}>
      <div className="d-flex w-100 flex-wrap">
        <div className="col-12 col-sm-6 p-0 pr-sm-1">
          <div className="react-date-picker-wrapper">
            <SingleDatePicker
              id={dateFieldId}
              disabled={disabled}
              date={
                timeZones
                  ? getConvertedMomentInFixedTimeZone(
                      value,
                      timeZones.fixedTimeZone,
                      timeZones.inputTimeZone,
                    )
                  : getConvertedMoment(value)
              }
              placeholder={placeholder}
              onDateChange={onDateChange}
              focused={focused}
              isOutsideRange={() => false}
              onFocusChange={e => {
                setFocused(e.focused);
                if (!e.focused) {
                  onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
                }
              }}
              customInputIcon={
                <span
                  style={{ color: colors.inputIcon }}
                  className="glyphicon glyphicon-calendar"
                />
              }
              numberOfMonths={width >= MOBILE_TABLET_WIDTH ? 2 : 1}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 p-0 pl-sm-1 mt-1 mt-sm-0">
          <Select
            inputId={timeFieldId}
            classNamePrefix={timeFieldId}
            value={
              timeZones
                ? {
                    value,
                    data: dateFormatter.timeInExactZone(
                      value,
                      timeZones.fixedTimeZone,
                      timeZones.inputTimeZone,
                    ),
                  }
                : { value, data: dateFormatter.time(value) }
            }
            options={options}
            styles={selectorCustomStyles}
            isDisabled={disabled}
            menuPlacement="auto"
            onInputChange={needle => {
              setOptions(prepareTimeFieldOptions(needle));
              return needle;
            }}
            filterOption={() => true}
            onChange={onTimeChange}
            onBlur={e => {
              onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
            }}
            formatOptionLabel={option => defaultOptionRenderer()(option.data)}
            components={{
              ValueContainer: TimeFieldValueContainerRenderer,
            }}
          />
        </div>
      </div>
    </FieldWrapper>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteRangeSelect extends Component {
  componentDidMount() {
    inspectionsFunctions.refresh_yes_no_na_buttons();
  }

  onClick = value => {
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const rangeLabel = range => `${range.min_value}-${range.max_value}`;

    return (
      <div
        className="range-button-group yes-no-container btn-group btn-group-toggle"
        data-toggle="buttons"
      >
        {this.props.config.ranges.map((range, index) => {
          if (!range.min_value || !range.max_value) {
            return null;
          }
          return (
            <button
              onClick={() => this.onClick(rangeLabel(range))}
              key={`${index}-${rangeLabel(range)}`}
              className="col btn btn-sm range-select-btn yes-no-btn"
              style={{ background: range.background, color: range.color || 'white' }}
            >
              <input
                name={this.props.resolveInputName(this.props.index, 'answer')}
                autoComplete="off"
                className="yes-no-checkbox"
                type="radio"
                defaultValue={rangeLabel(range)}
                defaultChecked={this.props.answer === rangeLabel(range)}
              />
              <span>{rangeLabel(range)}</span>
            </button>
          );
        })}
      </div>
    );
  }
}

SaluteRangeSelect.defaultProps = {
  onChange: () => {},
  answer: '',
};

SaluteRangeSelect.propTypes = {
  onChange: PropTypes.func,
  resolveInputName: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.string,
};

export default SaluteRangeSelect;

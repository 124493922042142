import React, { useState } from 'react';
import Select from 'react-select-next';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { defaultOptionRenderer } from './selectorOptionRenderers/defaultOptionRenderer';
import { prepareTimeFieldOptions } from './auxiliary/prepareTimeFieldOptions';
import { TimeFieldValueContainerRenderer } from './auxiliary/TimeFieldValueContainerRenderer';
import { getUtcMoment, momentFormatter, newUtcMoment } from '../../services/dateFormatUtils';
import {isDefined} from "../../services/utils";

export const TimeField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    placeholder,
    hideValue,
    resourceName,
    id,
  } = props;
  const [options, setOptions] = useState(prepareTimeFieldOptions());

  const inputId = id || `${resourceName}-${name}`;
  const displayedTime = !hideValue && value ? momentFormatter.time(getUtcMoment(value)) : null;
  const selectorValue = isDefined(value) ? { value, data: displayedTime } : null;

  const defaultOnChange = async newValue => {
    await setFieldValue(name, newValue);
    await setFieldTouched(name, true);
  };
  const defaultOnBlur = async () => {
    await setFieldTouched(name, true);
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <div style={{ width: '100%' }}>
        <Select
          inputId={inputId}
          classNamePrefix={inputId}
          value={selectorValue}
          options={options}
          styles={selectorCustomStyles}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          onInputChange={needle => {
            setOptions(prepareTimeFieldOptions(needle));
            return needle;
          }}
          filterOption={() => true}
          onChange={selection => {
            const momentInstance = newUtcMoment();
            momentInstance.set({
              hours: selection.hours,
              minutes: selection.minutes,
              seconds: 0,
            });
            const newValue = momentFormatter.serverDateTime(momentInstance);
            onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue);
          }}
          onBlur={e => {
            onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
          }}
          formatOptionLabel={option => defaultOptionRenderer()(option.data)}
          components={{
            ValueContainer: TimeFieldValueContainerRenderer,
          }}
        />
      </div>
    </FieldWrapper>
  );
};

import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { colors } from '../../assets/styles/colors';
import { isDefined } from '../../services/utils';
import { getConvertedMoment, momentFormatter } from '../../services/dateFormatUtils';

const MOBILE_TABLET_WIDTH = 1024;

export const DateField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    placeholder,
    withPortal = false,
    resourceName,
    id,
  } = props;
  const [focused, setFocused] = useState(false);
  const inputId = id || `${resourceName}-${name}`;
  const width = window.innerWidth;

  const defaultOnChange = async value => {
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
  };
  const defaultOnBlur = async () => {
    await setFieldTouched(name, true);
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <div className="react-date-picker-wrapper">
        <SingleDatePicker
          withPortal={withPortal}
          id={inputId}
          disabled={disabled}
          date={getConvertedMoment(value, true)}
          placeholder={placeholder}
          onDateChange={newDate => {
            const newValue = isDefined(newDate) ? momentFormatter.serverDate(newDate) : null;
            onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue);
          }}
          focused={focused}
          isOutsideRange={() => false}
          onFocusChange={e => {
            setFocused(e.focused);
            if (!e.focused) {
              onBlur ? onBlur(value, defaultOnBlur) : defaultOnBlur();
            }
          }}
          customInputIcon={
            <span style={{ color: colors.inputIcon }} className="glyphicon glyphicon-calendar" />
          }
          numberOfMonths={width >= MOBILE_TABLET_WIDTH ? 2 : 1}
        />
      </div>
    </FieldWrapper>
  );
};

import * as Yup from 'yup'

const countDecimalPlaces = (value) => {
  if (!isFinite(value)) return true
  let e = 1,
    p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p++;
  }
  return p;
}

function precision(precision = 2, message = 'Error precision') {
  return this.test({
    message,
    name: 'precision',
    exclusive: true,
    params: { precision },
    test(value) {
      return countDecimalPlaces(value) <= precision
    },
  })
}


Yup.addMethod(Yup.number, 'precision', precision)

export const YupValidator = Yup

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { FastField, Formik, useFormikContext } from 'formik';
import { renderProperInput } from '../../services/utils';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';

export const JsonField = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    config,
    resourceName,
    id,
  } = props;

  const UpdateValue = () => {
    const { values: localValues } = useFormikContext();
    useEffect(() => {
      if (!_.isEmpty(localValues)) {
        setFieldValue(name, localValues);
      }
    }, [localValues]);
    return null;
  };

  const initialValues = Object.fromEntries(
    config.map(field => [field.name, _.get(value, field.name, '')]),
  );

  const inputId = id || `${resourceName}-${name}`;

  return (
    <Formik initialValues={initialValues}>
      <Grid container>
        <Grid item xs={12}>
          {config?.map((field, index) => (
            <FastField
              {...field}
              id={inputId}
              key={`${inputId}-${index}`}
              name={field.name}
              label={field.label}
              required={field.required}
              hint={field.hint}
              placeholder={field.placeholder}
              component={renderProperInput(field.type)}
              displayMode={fieldDisplayModes.WIDE_FORM_ROW}
            />
          ))}
        </Grid>
        <UpdateValue />
      </Grid>
    </Formik>
  );
};

/** Lib */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/** Components */
import { ErrorMessage } from './ErrorMessage';
/** Style */
import { breakpoints } from '../../../Style/grid';
import { fontSize } from '../../../Style/typography';

/** @description
 *  FieldWrapper component is responsible for:
 *  1. Establishing a grid layout(proportions) for:
 *    - label
 *    - arbitrary input
 *    - error message if one exists
 *  2. Alignment of these components with respect to grid's column.
 *    - in case of textfield, label is centered vertically
 *    - in case of textarea, label is aligned to the top
 * */
export const FieldWrapper = props => {
  const {
    error,
    themeFor,
    inputComponent,
    labelComponent,
    fieldWidth,
    children,
    childrenWrapperClassName,
  } = props;

  let leftColumnWidths = ['lg-3', 'md-4', 'sm-3', '12'];
  let rightColumnWidths = [`lg-${getWidth(fieldWidth)}`, 'md-8', 'sm-9', '12'];

  if (fieldWidth === FIELD_WIDTHS.NEVER_FULL) {
    leftColumnWidths = ['lg-3', 'md-4', 'sm-3', '12'];
    rightColumnWidths = ['lg-4', 'md-6', 'sm-7', '10'];
  }

  const labelColumnWidthClasses = leftColumnWidths.map(width => `col-${width}`);
  const inputColumnWidthClasses = rightColumnWidths.map(width => `col-${width}`);

  const errorColumnOffsetClasses = leftColumnWidths.map(width => `offset-${width}`);
  const errorColumnWidthClasses = inputColumnWidthClasses;
  const errorColumnClasses = [...errorColumnWidthClasses, ...errorColumnOffsetClasses].join(' ');

  return (
    <FieldRow>
      <div className="row">
        <LabelColumn className={labelColumnWidthClasses.join(' ')} themeFor={themeFor}>
          {labelComponent}
        </LabelColumn>
        <div className={inputColumnWidthClasses.join(' ')}>{inputComponent}</div>
        {children && <div className={childrenWrapperClassName}>{children}</div>}
      </div>
      {error && (
        <div className="row">
          <div className={errorColumnClasses}>
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        </div>
      )}
    </FieldRow>
  );
};

FieldWrapper.propTypes = {
  inputComponent: PropTypes.element.isRequired,
  labelComponent: PropTypes.element.isRequired,
  error: PropTypes.string,
  themeFor: PropTypes.number,
  fieldWidth: PropTypes.number,
};

/** Customize label column styles depending on input type */

const FieldRow = styled.div`
  margin-top: 0.5rem;
  font-size: ${fontSize.textSm};
`;

const LabelColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: ${props => getTheme(props) && getTheme(props).verticalAlign};
  padding: 0px;
  @media (max-width: ${breakpoints.large}) {
    justify-content: flex-start;
    padding-left: 15px;
  }
`;

export const INPUT_TYPES = {
  TEXT_AREA: 0,
  TEXT_FIELD: 1,
  RADIO_BUTTONS: 2,
  ASYNC_SELECT: 3,
  DATE_PICKER: 4,
  DATE_RANGE_PICKER: 5,
  NUMERIC: 6,
  MULTIPLE_FILES_UPLOAD: 7,
  CURRENCY: 8,
  MULTIPLE_ASYNC_SELECT: 9,
  DATETIME_PICKER: 10,
  LINKS: 11,
  CHECKBOX: 12,
};

export const FIELD_WIDTHS = {
  NORMAL: 0,
  WIDE: 1,
  FULL: 2,
  NEVER_FULL: 3,
};

const THEMES = {
  [INPUT_TYPES.TEXT_AREA]: {
    verticalAlign: 'flex-start',
  },
  [INPUT_TYPES.TEXT_FIELD]: {
    verticalAlign: 'center',
  },
};

const getTheme = props => props.themeFor && THEMES[props.themeFor];
const getWidth = width => {
  switch (width) {
    case FIELD_WIDTHS.NORMAL:
      return 4;
    case FIELD_WIDTHS.WIDE:
      return 6;
    case FIELD_WIDTHS.FULL:
      return 9;
    default:
      return 4;
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SaluteTextArea extends Component {
  onChange = event => {
    const value = event.target.value;
    this.props.onChange && this.props.onChange(value);
  };

  placeholder() {
    if (this.props.config && this.props.config.placeholder) {
      return this.props.config.placeholder;
    }
    return '';
  }

  render() {
    return (
      <textarea
        name={this.props.resolveInputName(this.props.index, 'answer')}
        id="test"
        cols="30"
        rows="10"
        onChange={this.onChange}
        value={this.props.answer || ''}
        className="textarea-default"
        placeholder={this.placeholder()}
      />
    );
  }
}

SaluteTextArea.defaultProps = {
  onChange: () => {},
};

SaluteTextArea.propTypes = {
  onChange: PropTypes.func,
  resolveInputName: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default SaluteTextArea;

import React, { Component } from 'react';
import { bool } from 'prop-types';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import get from 'lodash/get';
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper';
import { getFormikErrors } from '../../services/formHelper';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { ErrorMessage } from '../../stateless_components/inputs/elements/ErrorMessage';

export const StyledLink = withStyles({
  root: {
    '&&': { color: '#007bff', paddingLeft: 8 },
  },
})(Link);

const I18nPath = 'activerecord.attributes.inspection_finding';
const objectTypesI18nPath = 'inspections.inspections.form.investigable_other';
const pascalToSnakeCase = text => {
  return text
    .replace(/[A-Z]/g, c => {
      return '_' + c.toLowerCase();
    })
    .replace(/^_/, '');
};

class ObjectSelector extends Component {
  apiPathsForEquipment = {
    Equipment: 'wcmcEquipments',
    PfE: 'pfEs',
  };
  apiPathsForLocation = {
    Lab: 'labs',
    BuildingsFloor: 'buildings_floors',
    Building: 'buildings',
    Campus: 'campuses',
  };

  isFinalized = () => {
    return this.props.values.status === 'finalized';
  };

  objectButton = objectType => {
    const { setFieldValue, values } = this.props;
    const defaultOnPress = () => {
      if (['Equipment', 'Location'].includes(objectType)) {
        setFieldValue('object_type', 'Location');
        setFieldValue('investigable_type', null);
        setFieldValue('investigable_id', null);
        setFieldValue(`show${objectType}Selector`, true);
      } else {
        setFieldValue('object_type', objectType);
        setFieldValue('investigable_type', objectType);
        setFieldValue('investigable_id', null);
      }
    };

    const objectTypes = values.available_object_types;

    const canShowObjectButton = !objectTypes || objectTypes.includes(objectType);

    if (canShowObjectButton) {
      return (
        <PrimaryButton
          disabled={this.isFinalized()}
          secondary
          className="mr-3 flex-grow-1"
          size="sm"
          onPress={defaultOnPress}
        >
          {I18n.t(`${objectTypesI18nPath}.${pascalToSnakeCase(objectType)}`)}
        </PrimaryButton>
      );
    }

    return null;
  };

  selectObjectButton = () => {
    const { errors, touched, required } = this.props;

    return (
      <>
        <div className="form-default">
          <div className="row form-group mb-0 mt-4">
            <div className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label pr-0">
              <label>
                Object <span className={`${required ? 'with-asterisk' : ''}`} />
              </label>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-9 col-xs-12">
              <div className="d-flex flex-row">
                {this.objectButton('PermitsFdny')}
                {this.objectButton('Equipment')}
                {this.objectButton('ConstructionProject')}
              </div>
              <div className="d-flex flex-row mt-2">
                {this.objectButton('BiologicalSafety')}
                {this.objectButton('Location')}
                {this.objectButton('Department')}
              </div>
              <div className="d-flex flex-row mt-2">
                {this.objectButton('SafeWorkAuthorization')}
                {this.objectButton('Incident')}
                {this.objectButton('Accident')}
              </div>
              <div className="d-flex flex-row mt-2">{this.objectButton('RadInventoryItem')}</div>
              {errors && (
                <ErrorMessage>{getFormikErrors('investigable_id', touched, errors)}</ErrorMessage>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  changeObjectButton = () => {
    return (
      <PrimaryButton
        secondary
        disabled={this.isFinalized()}
        size="sm"
        onPress={() => {
          this.props.setFieldValue('object_type', null);
          this.props.setFieldValue('investigable_type', null);
          this.props.setFieldValue('investigable.equipment_type_id', null);
          this.props.setFieldValue('investigable_id', null);
          this.props.setFieldValue('showEquipmentSelector', null);
          this.props.setFieldValue('showLocationSelector', null);
        }}
      >
        Change Object
      </PrimaryButton>
    );
  };
  viewObjectLink = () => {
    if (this.props.values.investigable) {
      const { url, type, id } = this.props.values.investigable;
      const { investigable_id, investigable_type } = this.props.values;
      const style = { height: '100%', display: 'flex', alignItems: 'center' };
      if (id === investigable_id && type === investigable_type && url) {
        return (
          <div style={style}>
            <StyledLink href={url} target="_blank" rel="noreferrer noopener">
              View Object
            </StyledLink>
          </div>
        );
      }
    }
    return null;
  };

  permitSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_permit`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'permits.index',
          intitialSelectionApiPath: 'permits.show',
          handleChange: value => setFieldValue('investigable_id', value),
          mapResult: item => ({
            value: item.id,
            id: item.id,
            label: item.text,
            header: item.header,
            details: item.details,
            responsiblePersonId: item.responsible_person_id,
          }),
          setSelectedRecord: value => {
            if (!values.responsible_person_id && value.responsiblePersonId) {
              setFieldValue('responsible_person_id', value.responsiblePersonId);
            }
          },
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  constructionProjectSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_construction_project`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'constructionProjects.index',
          intitialSelectionApiPath: 'constructionProjects.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  biologicalSafetySelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_biological_safety`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'biologicalSafeties.index',
          intitialSelectionApiPath: 'biologicalSafeties.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  equipmentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched } = this.props;
    return (
      <SaluteInputField
        labelProps={{ label: '' }}
        inputProps={{
          disabled: this.isFinalized() || !values.investigable_type,
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: `${this.apiPathsForEquipment[values.investigable_type]}.index`,
          requestParams: { type: values.investigable.equipment_type_id },
          intitialSelectionApiPath: `${this.apiPathsForEquipment[values.investigable_type]}.show`,
          handleChange: value => setFieldValue('investigable_id', value),
          mapResult: item => ({
            value: item.id,
            id: item.id,
            label: item.text,
            header: item.header,
            details: item.details,
            responsiblePersonId: item.responsible_person_id,
          }),
          setSelectedRecord: value => {
            if (!values.responsible_person_id && value.responsiblePersonId) {
              setFieldValue('responsible_person_id', value.responsiblePersonId);
            }
          },
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  equipmentTypeSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_equipment`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_type,
          name: 'investigable_type',
          initialSelection: values.investigable_type,
          handleChange: value => {
            setFieldValue('investigable_type', value);
            setFieldValue('investigable.equipment_type_id', null);
            setFieldValue('investigable_id', null);
          },
          options: () => {
            return {
              options: [
                {
                  id: 'Equipment',
                  value: 'Equipment',
                  header: I18n.t(`${I18nPath}.object.equipment.general_equipment`),
                },
                {
                  id: 'PfE',
                  value: 'PfE',
                  header: I18n.t(`${I18nPath}.object.equipment.pf_e`),
                },
              ],
              hasMore: false,
            };
          },
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_type', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
      </SaluteInputField>
    );
  };

  equipmentSubtypeSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched } = this.props;
    return (
      <SaluteInputField
        labelProps={{ label: I18n.t(`${I18nPath}.equipment_type`) }}
        inputProps={{
          disabled: this.isFinalized() || !values.investigable_type,
          setFieldTouched,
          value: values.investigable.equipment_type_id,
          initialSelection: values.investigable.equipment_type_id,
          apiPath: 'equipmentTypes.index',
          intitialSelectionApiPath: 'equipmentTypes.show',
          handleChange: value => {
            setFieldValue('investigable.equipment_type_id', value);
            setFieldValue('investigable_id', null);
          },
          mapResult: item => ({
            value: item.id,
            id: item.id,
            label: item.text,
            header: item.text,
          }),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable.equipment_type_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      />
    );
  };

  equipmentSelectorWrapper = () => {
    const { values } = this.props;
    return (
      <>
        {this.equipmentTypeSelector()}
        {values.investigable_type === 'Equipment' && this.equipmentSubtypeSelector()}
        {(values.investigable_type === 'PfE' || get(values, 'investigable.equipment_type_id')) &&
          this.equipmentSelector()}
      </>
    );
  };

  locationSelectorWrapper = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <>
        <SaluteInputField
          childrenWrapperClassName="change-object-btn"
          labelProps={{ label: I18n.t(`${I18nPath}.object_location`), required }}
          inputProps={{
            disabled: this.isFinalized(),
            setFieldTouched,
            value: values.investigable_type,
            name: 'investigable_type',
            initialSelection: values.investigable_type,
            handleChange: value => {
              setFieldValue('investigable_type', value);
              setFieldValue('investigable_id', null);
            },
            options: () => {
              return {
                options: [
                  {
                    id: 'Campus',
                    value: 'Campus',
                    header: I18n.t(`${I18nPath}.object.location.campus`),
                  },
                  {
                    id: 'Building',
                    value: 'Building',
                    header: I18n.t(`${I18nPath}.object.location.building`),
                  },
                  {
                    id: 'BuildingsFloor',
                    value: 'BuildingsFloor',
                    header: I18n.t(`${I18nPath}.object.location.buildings_floor`),
                  },
                  {
                    id: 'Lab',
                    value: 'Lab',
                    header: I18n.t(`${I18nPath}.object.location.lab`),
                  },
                ],
                hasMore: false,
              };
            },
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('investigable_type', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        >
          {this.changeObjectButton()}
        </SaluteInputField>
        <SaluteInputField
          labelProps={{ label: '' }}
          inputProps={{
            disabled: this.isFinalized() || !values.investigable_type,
            setFieldTouched,
            value: values.investigable_id,
            name: 'investigable_id',
            initialSelection: values.investigable_id,
            apiPath: `${this.apiPathsForLocation[values.investigable_type]}.index`,
            intitialSelectionApiPath: `${this.apiPathsForLocation[values.investigable_type]}.show`,
            handleChange: value => setFieldValue('investigable_id', value),
            mapResult: item => ({
              value: item.id,
              id: item.id,
              label: item.text,
              header: item.header + (item.active ? '' : ' (inactive)'),
              details: item.details,
              responsiblePersonId: item.responsible_person_id,
            }),
            setSelectedRecord: value => {
              if (!values.responsible_person_id && value.responsiblePersonId) {
                setFieldValue('responsible_person_id', value.responsiblePersonId);
              }
            },
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('investigable_id', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        >
          {this.viewObjectLink()}
        </SaluteInputField>
      </>
    );
  };

  departmentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_department`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'departments.index',
          intitialSelectionApiPath: 'departments.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  safeWorkAuthorizationSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_safe_work_authorization`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'safe_work_authorizations.index',
          intitialSelectionApiPath: 'safe_work_authorizations.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  incidentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_incident`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'incidents.index',
          intitialSelectionApiPath: 'incidents.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  accidentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_accident`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'accidents.index',
          intitialSelectionApiPath: 'accidents.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  radInventoryItemSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    return (
      <SaluteInputField
        childrenWrapperClassName="change-object-btn"
        labelProps={{ label: I18n.t(`${I18nPath}.object_rad_inventory_item`), required }}
        inputProps={{
          disabled: this.isFinalized(),
          setFieldTouched,
          value: values.investigable_id,
          name: 'investigable_id',
          initialSelection: values.investigable_id,
          apiPath: 'rad_inventory_items.index',
          intitialSelectionApiPath: 'rad_inventory_items.show',
          handleChange: value => setFieldValue('investigable_id', value),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        {this.changeObjectButton()}
        {this.viewObjectLink()}
      </SaluteInputField>
    );
  };

  showEquipmentSelector = () => {
    const { values } = this.props;

    return values.showEquipmentSelector || ['Equipment', 'PfE'].includes(values.investigable_type);
  };

  showLocationSelector = () => {
    const { values } = this.props;

    return (
      values.showLocationSelector ||
      ['Campus', 'Building', 'BuildingsFloor', 'Lab'].includes(values.investigable_type)
    );
  };

  render() {
    const { values } = this.props;

    if (
      !values.investigable_type &&
      !this.showEquipmentSelector() &&
      !this.showLocationSelector()
    ) {
      return this.selectObjectButton();
    }
    if (this.showEquipmentSelector()) return this.equipmentSelectorWrapper();
    if (this.showLocationSelector()) return this.locationSelectorWrapper();
    switch (values.investigable_type) {
      case 'PermitsFdny':
        return this.permitSelector();
      case 'ConstructionProject':
        return this.constructionProjectSelector();
      case 'BiologicalSafety':
        return this.biologicalSafetySelector();
      case 'Department':
        return this.departmentSelector();
      case 'SafeWorkAuthorization':
        return this.safeWorkAuthorizationSelector();
      case 'Incident':
        return this.incidentSelector();
      case 'Accident':
        return this.accidentSelector();
      case 'RadInventoryItem':
        return this.radInventoryItemSelector();
      default:
        return null;
    }
  }
}

ObjectSelector.propTypes = {
  required: bool,
};

ObjectSelector.defaultProps = {
  required: true,
};

export default ObjectSelector;

/** Lib */
import React, { Component } from 'react';
import { withFormik } from 'formik';
import _ from 'lodash';
import { connect } from 'react-redux';
/** Input components */
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
/** NonInput components */
import { SaluteSpinner } from '../../stateless_components/salute_spinner/index';
import { Tabs, Tab } from '../../stateless_components/tabs/Tabs';
import { FormContainer } from '../../stateless_components/form_wrappers/FormContainer';
import { FormSection } from '../../stateless_components/form_wrappers/FormSection';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { SaluteListItem } from '../../stateless_components/salute_list_item/index';
import { ButtonLink } from '../../stateless_components/buttons/ButtonLink';
import { ListEmptyPlaceholder } from '../../stateless_components/placeholders/ListEmptyPlaceholder';
import { SaluteOrganizationContext } from '../../stateless_components/contexts/SaluteOrganization';
import { Footer } from './Footer';
/** Modals */
import { ConfirmationGuardModal } from '../modals/ConfirmationGuardModal';
import { ManageWasteCollectionModal } from '../modals/ManageWasteCollectionModal/index';
import { Modal } from '../../stateless_components/modals/Modal';
/** Forms */
import { WasteManifestItemForm } from '../WasteManifestItem/Form';
/** Services */
import { API } from '../../services/api';
import { getFormikErrors, validateForm } from '../../services/formHelper';
/** Constants */
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper';
import validationSchema from './ValidationSchema';
import { AVAILABLE_MODALS } from '../../redux/views/Modals/setupRedux';

/** Redux */
import {
  wasteManifestItemsListActionCreators,
  wasteManifestCollectionNotCoveredListActionCreators,
} from '../../redux/views/waste_management/wasteManifestFormRedux';
import { wasteManifestActionRequestsActionCreators } from '../../redux/views/waste_management/wasteManifestFormRedux';
import { modalSetupActionCreators } from '../../redux/views/Modals/setupRedux';
/** Selectors */
import {
  wasteManifestLineItemsSelector,
  wasteManifestCollectionCoveredSelector,
} from '../../selectors/wasteManagementSelectors';
import { dateFormatter } from '../../services/dateFormatUtils';

const translate = (key, attributes = {}) => {
  return I18n.t(`waste_management.waste_manifests.form.${key}`, attributes);
};

/** == Container ======================================================================= */
class WasteManifestFormPartial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wasteManifestLineItemTemplate: props.initialWasteManifestLineItem,
      wasteManifestLineItemFormFor: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.values;

    if (id) {
      this.props.fetchWasteManifestItems(id);
    }
  }

  translate = (key, attributes = {}) => {
    return I18n.t(`waste_management.waste_manifests.form.${key}`, attributes);
  };

  removeWasteManifestLineItem = item => () => {
    this.props.removeWasteManifestItem(this.props.values.id, item.id);
  };

  dropWasteManifestCollectionItem = item => () => {
    this.props.dropWasteManifestCollectionItem(item.id);
  };

  renderWasteManifestItems = () => {
    return this.props.wasteManifestItems.map((item, index) => (
      <SaluteListItem
        key={`${index}-salute-list-item`}
        onListItemClick={this.setWasteManifestLineItemFormFor(item)}
        isRemoving={this.props.manifestItemsBeingRemoved.includes(item.id)}
        onRemoveItemClick={this.removeWasteManifestLineItem(item)}
        detailsList={[
          { header: translate('waste_manifest_items_list.dotun'), value: item.dotun },
          {
            header: translate('waste_manifest_items_list.container_count'),
            value: item.container_count,
          },
          {
            header: translate('waste_manifest_items_list.total_qty'),
            value: `${item.total_qty} ${item.total_um.um_code}`,
          },
          {
            header: translate('waste_manifest_items_list.actual_qty'),
            value: `${item.actual_qty} ${item.actual_um.um_code}`,
          },
        ]}
        subtitle={
          <div>
            <strong>{translate('waste_manifest_items_list.waste_codes')}</strong>
            {item.epa_codes}
          </div>
        }
        title={item.waste_type.waste_name}
      />
    ));
  };
  renderWasteManifestCollection = () => {
    return this.props.wasteManifestCollection.map((item, index) => (
      <SaluteListItem
        key={`${index}-salute-list-item-for-wc`}
        isRemoving={this.props.manifestCollectionItemsBeingRemoved.includes(item.id)}
        onRemoveItemClick={this.dropWasteManifestCollectionItem(item)}
        detailsList={[
          {
            header: translate('waste_manifest_collection_list.waste_chemical_name'),
            value: item.waste_chemical_name.chemical_name,
          },
          {
            header: translate('waste_manifest_collection_list.epa_codes'),
            value: item.epa_code,
          },
        ]}
        subtitle={dateFormatter.date(item.start_date)}
        title={item.identifier}
      />
    ));
  };

  setWasteManifestLineItemFormFor = record => () => {
    this.setState({
      wasteManifestLineItemFormFor: record,
    });
  };

  renderTabHeader = (entityName, isFetching, manifestId, listLength, onClick) => {
    if (manifestId === null) {
      // waste manifest line items
      return (
        <ListEmptyPlaceholder
          message={translate('not_saved_form_list_placeholder', { entityName })}
        />
      );
    } else if (!isFetching && manifestId && listLength > 0) {
      return (
        <div>
          <div className="d-flex justify-content-end">
            <div className=" mt-3 mb-0">
              <ButtonLink onClick={onClick}>{translate('add_element_to_list')}</ButtonLink>
            </div>
          </div>
          <hr className="mb-0" />
        </div>
      );
    } else if (!isFetching && manifestId && listLength === 0) {
      return (
        <ListEmptyPlaceholder message={translate('empty_list_placeholder', { entityName })}>
          <div className="mt-5">
            <PrimaryButton onPress={onClick}>
              {translate('add_element_to_empty_list')}
            </PrimaryButton>
          </div>
        </ListEmptyPlaceholder>
      );
    } else if (isFetching) {
      return (
        <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
          <SaluteSpinner />
        </div>
      );
    }
  };

  render() {
    const {
      handleChange,
      values,
      errors,
      setFieldValue,
      handleSubmit,
      isSubmitting,
      handleBlur,
      touched,
      setFieldTouched,
      wasteManifestItems,
      wasteManifestCollection,
      initialFormObject,
      isFetchingWasteManifestLineItems,
      fetchDataForWasteCollectionModal,
      current_organization,
      admin_access,
    } = this.props;

    const { Provider } = SaluteOrganizationContext;

    const { wasteManifestLineItemFormFor } = this.state;

    return (
      <Provider value={current_organization.short_name}>
        <Modal
          title={translate('modals.manifest_item')}
          isOpen={!!wasteManifestLineItemFormFor}
          onCloseClick={this.setWasteManifestLineItemFormFor(null)}
        >
          {wasteManifestLineItemFormFor && (
            <WasteManifestItemForm
              onCancellPress={this.setWasteManifestLineItemFormFor(null)}
              onSuccess={this.setWasteManifestLineItemFormFor(null)}
              initialFormObject={wasteManifestLineItemFormFor}
            />
          )}
        </Modal>
        <Modal
          modalId={AVAILABLE_MODALS.MANAGE_WASTE_COLLECTION}
          onAfterOpen={fetchDataForWasteCollectionModal}
          wrapperStyle={{ maxWidth: '1000px' }}
          title={translate('modals.manage_collection')}
        >
          <ManageWasteCollectionModal
            wasteManifestId={values.id}
            onCancelPress={this.props.closeModal}
            initialWasteGeneratorId={values.waste_generator_id}
          />
        </Modal>
        <ConfirmationGuardModal />
        <FormContainer>
          <FormSection title={translate('overview')}>
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.identifier'),
                required: true,
              }}
              inputProps={{
                value: values.identifier,
                name: 'identifier',
                onChange: handleChange,
                handleBlur,
              }}
              error={getFormikErrors('identifier', touched, errors)}
              type={INPUT_TYPES.TEXT_FIELD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.ship_date'),
                required: true,
              }}
              inputProps={{
                setFieldTouched,
                date: values.ship_date,
                name: 'ship_date',
                setFieldValue,
              }}
              error={getFormikErrors('ship_date', touched, errors)}
              type={INPUT_TYPES.DATE_PICKER}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.waste_generator'),
                required: true,
              }}
              inputProps={{
                setFieldTouched,
                value: values.waste_generator_id,
                name: 'waste_generator_id',
                initialSelection: initialFormObject.waste_generator,
                apiPath: 'wasteManagement.wasteGenerators.index',
                handleChange: value => setFieldValue('waste_generator_id', value),
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('waste_generator_id', touched, errors)}
              type={INPUT_TYPES.ASYNC_SELECT}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.waste_tsdf'),
                required: true,
              }}
              inputProps={{
                setFieldTouched,
                value: values.waste_tsdf_id,
                initialSelection: initialFormObject.waste_tsdf,
                name: 'waste_tsdf_id',
                apiPath: 'wasteManagement.wasteTsdfs.index',
                handleChange: value => setFieldValue('waste_tsdf_id', value),
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('waste_tsdf_id', touched, errors)}
              type={INPUT_TYPES.ASYNC_SELECT}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.manifest_comments'),
              }}
              inputProps={{
                handleBlur,
                value: values.manifest_comments || '',
                name: 'manifest_comments',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('manifest_comments', touched, errors)}
              type={INPUT_TYPES.TEXT_AREA}
            />
          </FormSection>
        </FormContainer>
        <FormContainer>
          <FormSection title={translate('documentation')}>
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.tsdf_attachments'),
              }}
              inputProps={{
                handleBlur,
                value: values.tsdf_attachments,
                name: 'tsdf_attachments',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('tsdf_attachments', touched, errors)}
              type={INPUT_TYPES.MULTIPLE_FILES_UPLOAD}
            />
            <SaluteInputField
              labelProps={{ label: I18n.t('activerecord.attributes.waste_manifest.tsdf_links') }}
              inputProps={{
                handleBlur,
                value: values.tsdf_links,
                name: 'tsdf_links',
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('tsdf_links', touched, errors)}
              type={INPUT_TYPES.LINKS}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t(
                  'activerecord.attributes.waste_manifest.tsdf_manifest_copy_attachments',
                ),
              }}
              inputProps={{
                handleBlur,
                value: values.tsdf_manifest_copy_attachments,
                name: 'tsdf_manifest_copy_attachments',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('tsdf_manifest_copy_attachments', touched, errors)}
              type={INPUT_TYPES.MULTIPLE_FILES_UPLOAD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.tsdf_manifest_copy_links'),
              }}
              inputProps={{
                handleBlur,
                value: values.tsdf_manifest_copy_links,
                name: 'tsdf_manifest_copy_links',
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('tsdf_manifest_copy_links', touched, errors)}
              type={INPUT_TYPES.LINKS}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t(
                  'activerecord.attributes.waste_manifest.certificate_of_destruction_attachments',
                ),
              }}
              inputProps={{
                handleBlur,
                value: values.certificate_of_destruction_attachments,
                name: 'certificate_of_destruction_attachments',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('certificate_of_destruction_attachments', touched, errors)}
              type={INPUT_TYPES.MULTIPLE_FILES_UPLOAD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t(
                  'activerecord.attributes.waste_manifest.certificate_of_destruction_links',
                ),
              }}
              inputProps={{
                handleBlur,
                value: values.certificate_of_destruction_links,
                name: 'certificate_of_destruction_links',
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('certificate_of_destruction_links', touched, errors)}
              type={INPUT_TYPES.LINKS}
            />
          </FormSection>
        </FormContainer>
        <FormContainer>
          <FormSection title={translate('invoicing')}>
            <SaluteInputField
              labelProps={{ label: I18n.t('activerecord.attributes.waste_manifest.invoice_no') }}
              inputProps={{
                handleBlur,
                value: values.invoice_no,
                name: 'invoice_no',
                onChange: handleChange,
              }}
              error={getFormikErrors('invoice_no', touched, errors)}
              type={INPUT_TYPES.TEXT_FIELD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.invoice_amount'),
              }}
              inputProps={{
                handleBlur,
                value: values.invoice_amount,
                name: 'invoice_amount',
                onChange: handleChange,
              }}
              error={getFormikErrors('invoice_amount', touched, errors)}
              type={INPUT_TYPES.TEXT_FIELD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.attached_invoices'),
              }}
              inputProps={{
                handleBlur,
                value: values.attached_invoices,
                name: 'attached_invoices',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('attached_invoices', touched, errors)}
              type={INPUT_TYPES.MULTIPLE_FILES_UPLOAD}
            />
            <SaluteInputField
              labelProps={{
                label: I18n.t('activerecord.attributes.waste_manifest.attached_invoices_links'),
              }}
              inputProps={{
                handleBlur,
                value: values.attached_invoices_links,
                name: 'attached_invoices_links',
              }}
              fieldWidth={FIELD_WIDTHS.FULL}
              error={getFormikErrors('attached_invoices_links', touched, errors)}
              type={INPUT_TYPES.LINKS}
            />
          </FormSection>
        </FormContainer>
        <div className="mt-4">
          <Tabs activeTabIndex={0}>
            <Tab title={translate('manifest_items')} bulletNumber={wasteManifestItems.length}>
              {this.renderTabHeader(
                'waste manifest line items',
                isFetchingWasteManifestLineItems,
                values.id,
                wasteManifestItems.length,
                this.setWasteManifestLineItemFormFor({
                  ...this.state.wasteManifestLineItemTemplate,
                  waste_manifest_id: values.id,
                }),
              )}
              {this.renderWasteManifestItems()}
            </Tab>
            <Tab
              title={translate('collections_covered')}
              bulletNumber={wasteManifestCollection.length}
            >
              {this.renderTabHeader(
                'waste collection covered items',
                isFetchingWasteManifestLineItems,
                values.id,
                wasteManifestCollection.length,
                () => this.props.openModal(AVAILABLE_MODALS.MANAGE_WASTE_COLLECTION),
              )}
              {this.renderWasteManifestCollection()}
            </Tab>
          </Tabs>
        </div>
        <Footer
          id={values.id}
          adminAccess={admin_access}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </Provider>
    );
  }
}

const WasteManifestFormContainer = withFormik({
  mapPropsToValues: ({ initialFormObject = {} }) => ({
    id: initialFormObject.id,
    identifier: initialFormObject.identifier,
    ship_date: initialFormObject.ship_date,
    waste_generator_id: initialFormObject.waste_generator_id,
    waste_tsdf_id: initialFormObject.waste_tsdf_id,
    manifest_comments: initialFormObject.manifest_comments,
    invoice_no: initialFormObject.invoice_no,
    invoice_amount: initialFormObject.invoice_amount,
    attached_invoices: initialFormObject.attached_invoices,
    tsdf_attachments: initialFormObject.tsdf_attachments,
    tsdf_manifest_copy_attachments: initialFormObject.tsdf_manifest_copy_attachments,
    certificate_of_destruction_attachments:
      initialFormObject.certificate_of_destruction_attachments,
    tsdf_links: initialFormObject.tsdf_links,
    tsdf_manifest_copy_links: initialFormObject.tsdf_manifest_copy_links,
    certificate_of_destruction_links: initialFormObject.certificate_of_destruction_links,
    attached_invoices_links: initialFormObject.attached_invoices_links,
  }),

  validate: values => {
    return validateForm(values, validationSchema);
  },

  handleSubmit: (values, props) => {
    const { setSubmitting, setErrors, setFieldValue } = props;
    setSubmitting(true);
    let asyncAction;

    if (values.id) {
      asyncAction = API.wasteManagement.wasteManifests.update;
    } else {
      asyncAction = API.wasteManagement.wasteManifests.create;
    }

    const updateBreadcrumbs = () => {
      const selector = $('.navbar_links a:last-child');
      selector.attr('href', window.location.href);
      selector.text('Waste Manifest');
    };

    return asyncAction(values).then(response => {
      if (response.ok) {
        props.setValues(response.data);
        const { id } = response.data;
        const newUrl = '/waste_management/waste_manifests/:id/edit'.replace(':id', id);
        setFieldValue('id', id);
        window.history.pushState('', '', newUrl);
        window.flashMessage(translate('flash_message.save.success'), true, 'success');
        updateBreadcrumbs();
      } else {
        window.flashMessage(translate('flash_message.save.error'), true, 'error');
        const errors = _.mapValues(response.data, value => value.join(' '));
        setErrors(errors);
      }

      $('html, body').animate({ scrollTop: 0 }, 300);
      setSubmitting(false);
    });
  },
})(WasteManifestFormPartial);

const mapStateToProps = state => ({
  wasteManifestItems: wasteManifestLineItemsSelector.allRecords(state),
  manifestItemsBeingRemoved: wasteManifestLineItemsSelector.idsOfRecordsBeingRemoved(state),
  isFetchingWasteManifestLineItems: wasteManifestLineItemsSelector.isFetching(state),
  manifestCollectionItemsBeingRemoved: wasteManifestCollectionCoveredSelector.idsOfRecordsBeingRemoved(
    state,
  ),
  wasteManifestCollection: wasteManifestCollectionCoveredSelector.allRecords(state),
});

const mapDispatchToProps = dispatch => ({
  openModal: modalId => dispatch(modalSetupActionCreators.openModal(modalId)),
  closeModal: () => dispatch(modalSetupActionCreators.closeModal()),
  fetchDataForWasteCollectionModal: () => {
    const params = { requestParams: { page: 1 } };
    return dispatch(wasteManifestCollectionNotCoveredListActionCreators.indexRequest(params));
  },
  fetchWasteManifestItems: manifestId => {
    dispatch(
      wasteManifestActionRequestsActionCreators.fetchTabsDataForWasteManifestForm({
        id: manifestId,
      }),
    );
  },
  dropWasteManifestCollectionItem: recordId => {
    const payload = [
      recordId,
      translate('waste_manifest_collection_list.drop_collection_item_confirmation'),
    ];
    dispatch(
      wasteManifestActionRequestsActionCreators.dropWasteCollectionFromCurrentManifestRequest(
        ...payload,
      ),
    );
  },
  removeWasteManifestItem: (wasteManifestId, id) => {
    const payload = {
      confirmationMessage: translate(
        'waste_manifest_items_list.drop_manifest_line_item_confirmation',
      ),
      implicationsMessage: translate(
        'waste_manifest_items_list.drop_manifest_line_item_implication',
      ),
      wasteManifestId,
      id,
    };

    return dispatch(wasteManifestItemsListActionCreators.destroyRequest(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WasteManifestFormContainer);

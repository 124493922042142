import React, { Component } from 'react';
import Checklist from '../../Checklist/Checklist';
import { SaluteSpinner } from '../../../stateless_components/salute_spinner/index';

class MonitorChecklist extends Component {
  componentDidMount = () => {
    this.loadChecklist();
    $('[data-toggle="tooltip"]').tooltip();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeMonitor !== this.props.activeMonitor) {
      this.loadChecklist();
    }
    inspectionsFunctions.refresh_yes_no_na_buttons();
    $('[data-toggle="tooltip"]').tooltip();
  }

  loadChecklist = () => {
    this.props.fetchChecklist();
  };

  checklist = () => {
    const {
      items,
      setAnswer,
      saveCommentAndFile,
      findingProps,
      disabled,
    } = this.props;
    return (
      <>
        <Checklist
          items={items}
          setAnswer={setAnswer}
          findingProps={findingProps}
          saveCommentAndFile={saveCommentAndFile}
          disabled={disabled}
          commentBtnLabel="Explanation +"
          resolveInputName={(i, attributeName) =>
            `fire_drill[monitor_checklist_questions_attributes][${i}][${attributeName}]`
          }
          canOpenCommentFileModal={() => true}
          canOpenFindingsModal={() => true}
          requiresExplanationOnNo
        />
      </>
    );
  };

  render() {
    const { fetching } = this.props;
    return (
      <>
        {fetching && (
          <div className="col-12">
            <SaluteSpinner style={{ margin: 'auto' }} name="circle" />
          </div>
        )}
        {!fetching && this.checklist()}
      </>
    );
  }
}

export default MonitorChecklist;

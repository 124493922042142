import React, { Component } from 'react';
import produce from 'immer';
import cloneDeep from 'lodash/fp/cloneDeep';
import Checklist from '../../Checklist/Checklist';
import { SaluteOrganizationContext } from '../../../stateless_components/contexts/SaluteOrganization';
import { SaluteSpinner } from '../../../stateless_components/salute_spinner/index';
import { PrimaryButton } from '../../../stateless_components/buttons/PrimaryButton';
import { API } from '../../../services/api';

const translate = key => I18n.t(`fire_drill.fire_drills.tabs.conductor_checklist.${key}`);

class ConductorChecklist extends Component {
  state = {
    items: [],
    activeRelatedEntity: '',
    saving: false,
    fetchQuestions: '',
    dirty: false,
  };

  componentDidMount = () => {
    this.loadChecklist();
    $('[data-toggle="tooltip"]').tooltip();
    document.querySelectorAll('#fire_drill_form').forEach(form => {
      form.addEventListener('submit', e => {
        if (this.state.dirty) {
          if (!window.confirm(translate('window_confirmation'))) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      });
    });
  };

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  loadChecklist = () => {
    this.setState({ fetchQuestions: true }, () => {
      this.updateChecklist();
    });
  };

  updateChecklist = () => {
    const { id } = this.props.params;
    API.conductorChecklistQuestion.questionAnswers(id).then(response => {
      this.setState({
        items: response.data.data.map(item => item.attributes),
        fetchQuestions: false,
      });
    });
  };

  isExplanationMissing = item => {
    const noAnswerCode = 2;
    return (
      item.answer_type === 'yes_no_na' &&
      parseInt(item.answer, 10) === noAnswerCode &&
      (item.comment === '' || item.comment === null) &&
      item.attachments.length === 0 &&
      item.links.length === 0
    );
  };

  checkRequiredExplanations = items => {
    let missingRequiredExplanation = false;
    items.forEach(item => {
      if (this.isExplanationMissing(item)) {
        missingRequiredExplanation = true;
      }
    });
    return missingRequiredExplanation;
  };

  saveAnswersBtnBody = missingRequiredExplanations => {
    if (missingRequiredExplanations) {
      return (
        <div data-toggle="tooltip" placement="top" title={translate('saving_button_tooltip')}>
          {translate('saving_button_title')}
        </div>
      );
    }
    return translate('saving_button_title');
  };

  saveAnswers = async () => {
    const { id } = this.props.params;
    const { items } = this.state;

    await new Promise(resolve => this.setState({ saving: true }, resolve));
    const response = await API.conductorChecklistQuestion.setAnswers(id, items);

    if (response.ok) {
      window.flashMessage(translate('success_saving_message'));
      this.setState({ dirty: false });
      const selector = $('#fire_drills-summary-category-input');
      const include_na_answers = $('#fire_drill_include_na_answers').is(':checked');
      $.get(
        `/fire_drills/${id}/edit?category_id=${selector.val()}&include_na_answers=${include_na_answers}`,
      );
    } else {
      window.flashMessage(translate('error_saving_message'), true, 'error');
    }
    this.setState({ saving: false });
    return response.ok;
  };

  setAnswer = (id, answer) => {
    this.setState(
      produce(this.state, draftState => {
        const question = draftState.items.find(item => item.id === id);
        question.answer = answer;
        draftState.dirty = true;
        return draftState;
      }),
    );
  };

  changeCommentAndFiles = (id, item) => {
    this.setState(
      produce(this.state, draftState => {
        const question = draftState.items.find(item => item.id === id);
        question.comment = item.comment;
        question.attachments = item.attachments;
        question.links = item.links;
        return draftState;
      }),
    );
  };

  saveCommentAndFile = (questionID, comment, attachments, links, afterSave) => {
    const { id } = this.props.params;
    const { items } = this.state;
    const question = cloneDeep(items.find(item => item.id === questionID));
    question.comment = comment;
    question.attachments = attachments;
    question.links = links;
    return API.conductorChecklistQuestion
      .saveCommentAndFile(id, questionID, question)
      .then(response => {
        if (response.ok) {
          window.flashMessage(translate('success_explanation_saving_message'));
          this.changeCommentAndFiles(questionID, question);
          afterSave(response.ok);
        } else {
          window.flashMessage(translate('error_saving_comment_message'), true, 'error');
        }
        return response;
      });
  };

  beforeOpeningChecklistModal = async (missingRequiredExplanations, fire_drill_finalized) => {
    if (fire_drill_finalized) return true;
    if (missingRequiredExplanations) {
      window.flashMessage(translate('missing_explanations_error'), true, 'error');
      return false;
    }
    const result = await this.saveAnswers();
    return result;
  };

  checklist = () => {
    const { activeRelatedEntity, items } = this.state;
    const {
      admin_access,
      conductor_id,
      fire_drill_finalized,
      current_organization,
      people_groups_count,
      fire_drill_people_group_id,
      internal,
      enums,
    } = this.props;
    const findingsModuleProps = {
      setActiveRelatedEntity: activeRelatedQuestion =>
        this.setState({ activeRelatedEntity: activeRelatedQuestion }),
      activeRelatedEntity,
      refreshList: this.updateChecklist,
      adminAccess: admin_access,
      peopleGroupsCount: people_groups_count,
      findingStatuses: enums.inspection_finding_status,
      defaults: {
        creator_id: conductor_id,
        fire_drill_question_answer_id: activeRelatedEntity.id,
        people_group_id: fire_drill_people_group_id,
        internal,
      },
    };
    const { Provider } = SaluteOrganizationContext;
    const missingRequiredExplanations = this.checkRequiredExplanations(items);

    return (
      <Provider value={current_organization.short_name}>
        <Checklist
          items={this.state.items}
          setAnswer={this.setAnswer}
          disabled={fire_drill_finalized}
          findingProps={findingsModuleProps}
          saveCommentAndFile={this.saveCommentAndFile}
          commentBtnLabel="+ Explanation"
          resolveInputName={(i, attributeName) =>
            `fire_drill[conductor_checklist_questions_attributes][${i}][${attributeName}]`
          }
          canOpenCommentFileModal={() => true}
          canOpenFindingsModal={() =>
            this.beforeOpeningChecklistModal(missingRequiredExplanations, fire_drill_finalized)
          }
          requiresExplanationOnNo
        />
        <div className="text-right mt-3">
          <input type="hidden" id="conductor_form_state" value={this.state.dirty} />
          {!fire_drill_finalized && (
            <PrimaryButton
              disabled={this.state.saving || missingRequiredExplanations}
              onPress={this.saveAnswers}
              isPending={this.state.saving}
              noFadeIn
            >
              {this.saveAnswersBtnBody(missingRequiredExplanations)}
            </PrimaryButton>
          )}
        </div>
      </Provider>
    );
  };

  render() {
    const { fetchQuestions } = this.state;
    return (
      <>
        {fetchQuestions && (
          <div className="col-12">
            <SaluteSpinner style={{ margin: 'auto' }} name="circle" />
          </div>
        )}
        {!fetchQuestions && this.checklist()}
      </>
    );
  }
}

export default ConductorChecklist;

/** Style */
import styled from 'styled-components'
import { fontSize } from '../../../../Style/typography'
import { colors } from '../../../../Style/colors'

export const StyledAttachmentNameSpan = styled.span`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
`

export const StyledAttachmentLink = styled.a`
  color: ${colors.download};
  min-width: 80px;
`

export const StyledRemoveAttachmentButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  margin-left: 15px;
  color: ${colors.delete};
  cursor: pointer;
  &:focus, &:hover {
    text-decoration: underline;
  }
`

export const StyledAttachmentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px;
`

export const StyledAttachmentsTable = styled.div`
  font-size: ${fontSize.textXsm};
  flex-direction: column;
`

export const StyledMockUploadButton = styled.div`
  padding: 0.25rem 1.0rem;
  border: 1px solid  ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  &:focus, &:hover {
    background: ${colors.inputBorderColor};
  }
`

export const StyledMockUploadButtonTitle = styled.span`
  padding-left: 10px;
`
